import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import Matrix from "../components/matrix";
import { getPrettyDate } from "../utils/dates";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SectionHead from "../components/newComponents/sectionHead";
import SocialMediaButtons from "../components/newComponents/socialShare";
import EditLink from "../components/newComponents/editLink";

const NewsEntry = ({ data: { entry, site }, pageContext }) => {

  const category = (entry.newsCategory[0]?.title && entry.newsCategory[0]?.title != null ? entry.newsCategory[0]?.title : "");
  const byline = (entry.byline && entry.byline != null ? entry.byline : "");
  const finalByline = `${category}${category && byline && " | "}${byline}`;

  return (
    <Layout pageContext={pageContext}>
      <Seo title={entry.displayTitle} image={entry.featureImage[0]?.url} />
      <div className="main">
        <section className="section-inside">
          <div className="shell">
            <SectionHead image={entry.featureImage[0]} heading={entry.displayTitle} date={getPrettyDate(entry.postDate)} byline={finalByline}/>
            <Matrix blocks={entry.bodyContent}/>
            <EditLink remoteId={entry.remoteId} sectionHandle={entry.sectionHandle}/>
            <footer className="section__foot">
              <div className="section__socials">
                <p><strong>Share Article</strong></p>
                <SocialMediaButtons url={`${site.siteMetadata.url}/${entry.uri}`} title={`Genesis Analytics: ${entry.displayTitle}`} summary={entry.summary} twitterVia="GenesisEconomic" linkedInSource="Genesis Analytics"/>
              </div>
              <Link to="/news" className="section__link link-more link-more--back text-gray-3">Back to news</Link>
            </footer>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default NewsEntry;

export const query = graphql`
  query NewsPostQuery($slug: String!) {
    entry: craftNewsNewsEntry(slug: { eq: $slug }) {
      id
      remoteId
      sectionHandle
      displayTitle
      url
      uri
      postDate
      byline
      newsCategory {
        ... on Craft_categoryNews_newsCategory_Entry {
          id
          title
        }
      }
      featureImage {
        ...AssetFragment
      }
      bodyContent {
        ...BodyContentFragment
      }
    }

    site: site {
      siteMetadata {
        url
      }
    }
  }
`;